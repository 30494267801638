import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://www.linkedin.com/in/michal-verner-mv/"
            className="icon fa-linkedin"
            alt="LinkedIn link"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/verner_michal"
            className="icon fa-twitter"
            alt="Twitter link"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/vernemi2"
            className="icon fa-github"
            alt="Github repo link"
          >
            <span className="label">Github</span>
          </a>
        </li>
        <li>
          <a
            href="mailto:michal.verner.mv@gmail.com"
            className="icon fa-envelope-o"
            alt="Email address"
          >
            <span className="label">Email</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; Michal Verner</li>
        <li>
          Design: <a href="http://html5up.net">HTML5 UP</a>
        </li>
      </ul>
    </div>
  </div>
)

export default Footer
