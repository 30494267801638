import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'
import '../assets/scss/main.scss'
import Header from './Header'

const Template = ({ children }) => {
  const location = useLocation()

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        This site uses analytics cookies to provide better user experience
      </CookieConsent>

      <Header />
      {children}
    </div>
  )
}

export default Template
