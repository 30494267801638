import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
      <a href="/" className="image avatar">
        <img src={avatar} alt="Michal's avatar" />
      </a>
      <h1>
        <strong>Michal Verner</strong>
      </h1>
      <h4>Salesforce developer</h4>
      <p>Like XC cycling, running, reading and deving new stuff</p>
    </div>
    <Footer />
  </header>
)

export default Header
